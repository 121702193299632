import React from "react";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="section ">
      <div className="container px-lg-5">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start">
            <p className="mb-3 mb-lg-0">
              Sité réalisé par&nbsp;
              <a
                href="https://opalia.dev"
                className="fw-500"
              >
                Opalia
              </a>
              . Tout droits réservés.
            </p>
          </div>
          <div className="col-lg-6">
            <ul className="nav nav-separator justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#terms-policy"
                  href="#terms-policy"
                >
                  Mentions légales
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
