import React from "react";

const Erp = () => {
return (
<div id="erp" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                L'État des Risques et Pollutions
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

            <p>
                <strong>
                    L'État des Risques et Pollutions (ERP) constitue une obligation importante lors de transactions
                    immobilières
                </strong>, que ce soit pour la vente ou la location d'un bien. Voici quelques éléments essentiels à
                retenir à ce sujet, ainsi que les raisons pour lesquelles il peut être utile de faire appel à un
                diagnostiqueur comme DIAG AUDIT EXPERTISE : <br/><br/>
                Obligation de l'ERP : Le formulaire ERP doit être complété dès lors que le bien immobilier est situé
                dans une zone soumise à des risques spécifiques, tels que des risques miniers, naturels, technologiques,
                sismiques, ou en présence potentielle de radon.<br/><br/>
                Rôle des Diagnostiqueurs : Les diagnostiqueurs immobiliers, tels que DIAG AUDIT EXPERTISE, peuvent
                conseiller les vendeurs et les bailleurs sur l'obligation de fournir un ERP. Ils sont en mesure de
                déterminer si le bien est concerné par ces risques et peuvent remplir le formulaire en conséquence.<br/><br/>
                Importance de l'ERP : L'absence du formulaire ERP dans le Dossier de Diagnostic Technique (DDT) peut
                avoir des conséquences sur la transaction immobilière. Que ce soit pour une location ou une vente, le
                non-respect de cette obligation peut remettre en cause la validité de la transaction.<br/><br/>
                Compensation Financière : En cas de non-fourniture de l'ERP alors qu'il est obligatoire, les locataires
                ou les acheteurs peuvent potentiellement demander une compensation financière. Il est donc crucial de
                s'assurer de la complétion de ce formulaire.<br/><br/>
                En résumé, l'État des Risques et Pollutions est une étape essentielle dans le processus de vente ou de
                location, visant à informer les futurs occupants sur les risques éventuels liés à la localisation du
                bien. Les diagnostiqueurs immobiliers peuvent apporter leur expertise pour garantir la conformité et la
                validité de cette démarche, évitant ainsi d'éventuels litiges ou complications lors de la transaction
                immobilière.
            </p>

            </div>
        </div>
    </div>
</div>
);
};

export default Erp;