import React from "react";

const Dtg = () => {
return (
<div id="dtg" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                DTG
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p><strong>Les conclusions du DTG doivent être transmises au futur acquéreur d'un lot de copropriété</strong>. Quels sont
                    les immeubles concernés par le DTG ?</p>
                <p><strong>Le DTG est obligatoire dans certains cas. L'assemblée générale des copropriétaire</strong>s peut décider à la
                    majorité simple de faire réaliser un DTG, mais elle n'y est pas obligée.</p>
                <p>Si un DTG a été réalisé, le syndicat de copropriétaires est dispensé de mettre en place un plan
                    pluriannuel de travaux (PPT) à la condition que ce DTG fait apparaître qu'il n'y a aucun besoin de
                    travaux au cours des 10 années qui suivent son élaboration.</p>
                <p><strong>Le DTG est obligatoire pour un immeuble de plus de 10 ans</strong> et qui fait l'objet d'une mise en
                    copropriété. C'est le cas lorsqu'un immeuble est nouvellement divisé en plusieurs lots privatifs et
                    communs.</p>
                <p><strong>
                    L'administration peut demander au syndic de présenter le DTG lorsque l'immeuble présente des
                        désordres (par exemple,
                </strong> l'immeuble est insalubre ou en péril) pour vérifier l'état de bon usage et
                    de sécurité des parties communes.</p>
                <p><strong>La réalisation du DTG doit être confiée à un professionnel</strong>. Il peut s'agir d'un architecte, bureau
                    d'études, thermicien ou diagnostiqueur immobilier justifiant de certaines compétences.</p>
                <p>Ce professionnel doit attester sur l'honneur de son impartialité et de son indépendance à l'égard du
                    syndic, des fournisseurs d'énergies et des entreprises intervenant sur l'immeuble.
                    Il doit également justifier avoir souscrit une assurance de responsabilité civile professionnelle.
                </p>
                <p><strong>Le DTG doit comporter les informations suivantes :</strong>
                    Analyse de l'état apparent des parties communes et des équipements communs de l'immeuble <br/>
                    État technique de l'immeuble au regard des obligations légales et réglementaires au titre de la
                    construction<br/>
                    Analyse des améliorations possibles de la gestion technique et patrimoniale de l'immeuble
                    Diagnostic de performance énergétique de l'immeuble<br/>
                    Évaluation sommaire du coût et une liste des travaux nécessaire à la conservation de l'immeuble, à
                    la préservation de la santé et de la sécurité des occupants et à la réalisation d'économies
                    d'énergie. Cette évaluation doit notamment préciser les travaux qui doivent être menés dans les 10
                    prochaines années.</p>
                <p><strong>
                    Le maire ou le préfet ou le président d'établissement public de coopération intercommunale peut à
                        tout moment demander au syndic de lui produire le DTG pour vérifier l'état de bon usage et de
                        sécurité des parties communes présentant des désordres potentiels.
                </strong></p>
                <p>À défaut de production du DTG dans un délai d'un mois après notification de la demande, le maire ou
                    le préfet ou le président d'établissement public de coopération intercommunale peut faire réaliser
                    d'office le DTG à la place et aux frais du syndicat des copropriétaires.</p>

            </div>
        </div>
    </div>
</div>
);
};

export default Dtg;