import React from "react";

const Electricite = () => {
return (
<div id="electricite" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Electricité
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p>Le <strong>diagnostic électrique</strong> est obligatoire en cas de vente ou de location d'un bien immobilier à usage
                    d'habitation, à condition que les installations électriques aient été réalisées il y a plus de 15
                    ans. Pour les locations, cette obligation a été introduite progressivement, débutant avec les
                    appartements construits avant 1975 en 2017, puis s'étendant à toutes les installations de <strong> plus de 15 ans en 2018
                    </strong>, couvrant maisons et appartements, meublés ou nus.</p>
                <p>Le <strong>diagnostic électrique</strong>, à la charge du propriétaire ou du vendeur, doit être annexé à l'ensemble
                    des diagnostics immobiliers obligatoires, au compromis de vente ou au bail.</p>
                <p>Seul un diagnostiqueur certifié, habilité et assuré peut réaliser ce diagnostic conformément au
                    fascicule de documentation FD C 16-600 de juin 2015. Le diagnostiqueur inspecte près d'une centaine
                    de points de contrôle, dont le disjoncteur de branchement, les fusibles, disjoncteurs, dispositifs
                    différentiels, la prise de terre, les liaisons équipotentielles, les classes de matériels
                    électriques, et le respect des distances par rapport aux points d'eau, entre autres. Seuls les
                    tableaux électriques sont susceptibles d'être démontés pendant les contrôles.</p>
                <p>Le <strong>diagnostic électrique</strong> ne génère pas d'obligation de travaux, mais il informe sur l'état de
                    l'installation électrique, sa vétusté ou sa dangerosité. La responsabilité du propriétaire-bailleur
                    peut être engagée en cas d'accident ou d'incendie résultant de la vétusté de l'installation
                    électrique.</p>
                <p>La durée de validité du <strong>diagnostic électrique</strong> est de 3 ans pour une vente et de 6 ans pour une
                    location, transmissible lors de l'acte de vente ou de la signature du bail. Une attestation de
                    conformité de moins de 3 ans peut remplacer le diagnostic immobilier. Les normes électriques pour
                    les salles de bains sont basées sur des volumes définis, et la norme électrique NF C15-100, en
                    vigueur depuis 2015, régit les équipements autorisés et les degrés de protection dans ces volumes.
                </p>
                <p>En conclusion, le <strong>diagnostic électrique</strong> offre une vision détaillée de l'état de l'installation
                    électrique d'une habitation, contribuant ainsi à la sécurité des occupants et à la prévention des
                    risques liés à l'électricité.</p>

            </div>
        </div>
    </div>
</div>
);
};

export default Electricite;