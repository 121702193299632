import React from "react";

const Mesurage = () => {
return (
<div id="mesurage" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                Mesurage
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">
                <p>
                    La loi Carrez et la loi Boutin sont deux dispositifs distincts en France, chacun ayant ses propres
                    objectifs et applications. Voici quelques points de différenciation supplémentaires :
                </p>
                <p>
                    <strong>Loi Carrez :</strong><br/>
                    - Date d'application : La loi Carrez a été promulguée en 1996. <br/>
                    - Public cible : Elle s'adresse principalement aux vendeurs de lots en copropriété.<br/>
                    - Objectif : La loi Carrez vise à assurer une mesure précise de la superficie privative des biens en
                    copropriété afin de fournir une information fiable aux acheteurs potentiels.<br/>
                    - La surface loi Carrez correspond à la surface réellement habitable d’un logement. Pour calculer la
                    surface Carrez il faut donc mesurer la superficie des planchers, en y soustrayant les éléments suivants
                    :<br/>
                    - les murs et cloisons ; <br/>
                    - les marches et cages d’escalier ; <br/>
                    - les embrasures de portes et fenêtres ;<br/>
                    - les gaines ;<br/>
                    - les balcons et terrasses ;<br/>
                    - les annexes telles que cave ou garage.<br/><br/>

                    Seules les surfaces dont la<strong> hauteur sous plafond atteint 1,80 m</strong> sont prises en compte dans le calcul de
                    la surface Carrez. Les lots ou fractions de <strong>lot de moins de 8 m²</strong> ne rentrent dans le calcul que s’ils
                    sont totalement intégrés au bien. Par exemple, une petite chambre de bonne indépendante ne comptera pas.
                    En revanche, s’il s’agit bien d’espaces clos et couverts de plus de 1,80 m sous plafond, sont mesurés :<br/><br/>

                    - le sous-sol (hors cave et parking) ;<br/>
                    - le grenier et les combles, même non aménagés ;<br/>
                    - les réserves et remises ;<br/>
                    - ou encore une véranda.<br/><br/>
                    <strong>
                        Obligations contractuelles : Le vendeur doit mentionner la superficie Carrez dans les documents de
                        vente, et les erreurs significatives peuvent entraîner des conséquences juridiques.
                    </strong>
                </p>
                <p>
                    <strong>Loi Boutin :</strong> <br/>
                    Date d'application : La loi Boutin a été votée en 2009.<br/>
                    Public cible : Elle concerne les bailleurs de logements.<br/>
                    Objectif : La loi Boutin a pour objectif de standardiser la mesure de la surface habitable des logements
                    loués afin d'apporter une plus grande transparence pour les locataires.<br/>
                    La surface habitable prise en compte dans le diagnostic loi Boutin correspond à la surface de plancher
                    construite à laquelle on retire les éléments suivants :<br/>
                    - Murs<br/>
                    - Cloisons<br/>
                    - Marches et cages d'escaliers<br/>
                    - Gaines<br/>
                    - Embrasures de portes et fenêtres<br/>
                    - Pièces dont la hauteur est inférieure à 1,80m.<br/><br/>
                    Les pièces non destinées à un usage d'habitation sont à exclure du métrage loi Boutin, telles que :<br/>
                    - Les combles non aménagés<br/>
                    - Les caves, sous-sols, remises...<br/>
                    - Les garages et autres dépendances<br/>
                    - Les terrasses, balcons ou vérandas<br/>
                    - Les parties communes.<br/>
                </p>
                <p>
                    Obligations contractuelles : Les bailleurs sont tenus d'indiquer la surface habitable dans le contrat de
                    location.
                </p>
                <p>
                    <strong>
                        En résumé, la loi Carrez vise à fournir des informations précises aux acheteurs de biens en copropriété,
                        tandis que la loi Boutin standardise la mesure de la surface habitable pour les locations
                        résidentielles. Ces deux lois répondent à des besoins spécifiques dans le cadre des transactions
                        immobilières et locatives.
                    </strong>
                </p>

            </div>
        </div>
    </div>
</div>
);
};

export default Mesurage;