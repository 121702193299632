import React from "react";

const Termites = () => {
return (
<div id="termites" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Termites
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p><strong>
                    Le diagnostic relatif à la présence des termites est effectivement obligatoire uniquement pour vendre
                        un bien immobilier. Il concerne les biens situés dans des communes déclarées infestées par les
                        termites, et dans ces cas, il doit être réalisé avant la vente.
                </strong></p>
                <p>En cas de doute ou pour sécuriser le bien immobilier, les propriétaires ont la possibilité de
                    demander un diagnostic termite, même s'il n'est pas obligatoire. Ce diagnostic permet de détecter la
                    présence éventuelle de termites ou d'insectes xylophages dans le logement.</p>
                <p><strong>Le cabinet DIAG AUDIT EXPERTISE offre des services de diagnostic termite</strong> pour aider les propriétaires
                    à protéger leur propriété. Les diagnostiqueurs interviennent pour sonder toutes les parties du bien
                    contenant du bois, notamment les poutres, les plafonds et les planchers. En cas de présence de
                    termites, des recommandations seront formulées pour traiter l'infestation.</p>
                <p><strong>Le diagnostic termite est nécessaire dans le cadre d'une vente immobilière dans les zones déclarées</strong>
                    infestées. Il doit être effectué par un diagnostiqueur certifié par un organisme accrédité COFRAC
                    (Comité français d'accréditation). La mission de recherche de termites est non-destructive et porte
                    uniquement sur les éléments en bois visibles et accessibles.</p>
                <p>Si le diagnostic termite révèle la présence de termites, le vendeur doit en informer l'acheteur, et
                    des mesures appropriées doivent être prises pour traiter l'infestation. <strong>
                        La validité du diagnostic
                        termite est de 6 mois,
                    </strong> et il doit avoir été réalisé récemment, généralement moins de six mois avant
                    la signature du compromis de vente.</p>
                <p>Il est également souligné que la présence de termites peut entraîner des dégâts importants dans les
                    bâtiments en dégradant le bois, et cela peut affecter la structure du bâtiment, voire conduire à son
                    effondrement dans les cas les plus extrêmes.</p>
            </div>
        </div>
    </div>
</div>
);
};

export default Termites;