import React from "react";

const Assainissement = () => {
return (
<div id="assainissement" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                Diagnostic de l'assainissement
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p> 
                    Le diagnostic de l'assainissement revêt une importance cruciale lors de la vente d'un logement,
                    notamment lorsque le bien n'est pas raccordé au réseau public d'assainissement collectif. Voici
                    quelques points à retenir concernant cette obligation et le rôle du diagnostic assainissement :<br/><br/>
                    Obligation de Diagnostic : <strong>les vendeurs sont tenus de réaliser un diagnostic assainissement</strong>. Ce
                    diagnostic vise à vérifier la conformité et la sécurité de l'installation autonome d'assainissement
                    (par exemple, une fosse septique).<br/><br/>
                    Objectif du Contrôle : Le but principal du diagnostic est de <strong>
                        constater si l'installation
                        d'assainissement est conforme aux normes en vigueur
                    </strong> et si elle garantit la sécurité. En cas de
                    non-conformité ou de défaut, des travaux peuvent être nécessaires.<br/><br/>
                    Responsabilité pour les Travaux : <strong>
                        En cas de non-conformité, la responsabilité des travaux peut
                        incomber soit au vendeur, qui peut ainsi optimiser la vente en proposant un bien conforme, soit à
                        l'acheteur, qui dispose d'un délai d'un an pour effectuer les travaux nécessaires
                    </strong>.<br/><br/>
                    Contact avec un Professionnel : En cas de doute sur les obligations spécifiques de diagnostic
                    assainissement, les vendeurs peuvent <strong>
                        se tourner vers des professionnels qualifiés tels que DIAG
                        AUDIT AXPERTISE.
                    </strong> Ces cabinets spécialisés sont en mesure de réaliser les contrôles nécessaires pour
                    les installations d'assainissement, que ce soit pour des systèmes collectifs ou individuels.<br/><br/>
                    En résumé, le diagnostic assainissement est une étape clé pour assurer la conformité et la sécurité
                    des installations d'assainissement, ce qui est essentiel lors d'une transaction immobilière. Les
                    vendeurs ont la responsabilité de s'assurer de la réalisation de ce diagnostic, contribuant ainsi à
                    une vente transparente et conforme aux normes en vigueur.
                </p>
            </div>
        </div>
    </div>
</div>
);
};

export default Assainissement;