import React from "react";

const Dpe = () => {
return (
<div id="dpe" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    DPE
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p>Les objectifs du <strong>diagnostic de performance énergétique (DPE)</strong> sont les suivants : <br/>
                    Informer les futurs occupants sur la consommation d'énergie et l'empreinte carbone du bien  <br/>
                    immobilier.
                    Contribuer à la réduction des émissions de CO₂ pour répondre aux enjeux environnementaux actuels.
                </p>
                <p>Le DPE comprend les éléments suivants :<br/>
                    - Des Informations sur la consommation d'énergie et l'empreinte carbone du bien.<br/>
                    - Un classement énergétique pour faciliter la comparaison entre les biens immobiliers.<br/>
                    - Des recommandations d'usages et de travaux visant à économiser de l'énergie.</p>
                <p>Une réforme récente a été apportée au DPE pour le rendre plus fiable, lisible et opposable. La
                    nouvelle méthode de calcul, appelée <strong>3CL DPE 2021</strong>, est désormais utilisée pour tous les logements,
                    qu'ils soient existants ou neufs, à vendre ou à louer. Cette méthode prend en compte cinq postes de
                    consommation : chauffage, refroidissement, eau chaude sanitaire, éclairage et auxiliaires.</p>
                <p>Le rapport du DPE présente une étiquette double-seuil pour les gaz à effet de serre et la
                    consommation d'énergie primaire. D'autres informations telles que le confort d'été, l'isolation de
                    l'enveloppe et une estimation des coûts annuels d'énergie ont été ajoutées. Les propriétaires ont
                    également de nouvelles obligations d'affichage dans les annonces immobilières.</p>
                <p>Le design du rapport a été modifié pour le rendre plus compréhensible, et les préconisations de
                    travaux sont présentées avec deux bouquets de travaux, l'un pour sortir le bien du statut de
                    passoire énergétique et l'autre pour améliorer sa performance énergétique.</p>
                <p><strong>
                    Le DPE est obligatoire avant la publication des annonces immobilières, après des travaux de
                        rénovation susceptibles de modifier la performance énergétique, et s'il devient invalide, par
                        exemple, s'il date d'avant le 1er juillet 2021. L'ancien DPE cesse d'être valable au 1er janvier
                        2023 s'il a été réalisé avant le 31 décembre 2017, et au 1er janvier 2025 s'il a été réalisé entre
                        le 1er janvier 2018 et le 30 juin 2021.
                </strong></p>

            </div>
        </div>
    </div>
</div>
);
};

export default Dpe;