import React from "react";

const Merule = () => {
return (
<div id="merule" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Mérule
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">
                <p><strong>La mérule</strong>, souvent appelé la "lèpre des maisons", peut infliger de graves séquelles à la décence des
                    biens immobiliers. Ce champignon se dissimule dans la structure du bois, rendant son repérage
                    difficile. Une fois qu'il est découvert, cela signifie généralement qu'il est déjà trop tard. La
                    mérule fragilise les structures du logement, au point de le rendre indécent, ce qui peut entraîner
                    une interdiction de vente et de location.</p>

                <p><strong>
                    Pour les biens situés en dehors des zones réglementées, bien que le diagnostic mérule ne soit pas
                        obligatoire, il est fortement recommandé
                </strong>, notamment par les notaires. Cela s'explique par le fait
                    que l'état relatif à la présence de mérule est souvent complémentaire au diagnostic termites
                    obligatoire, car les deux concernent des emplacements similaires. Le vendeur peut demander cette
                    expertise lors de la réalisation de l'état relatif à la présence de termites, ou s'il a des
                    préoccupations concernant l'état de la charpente et l'humidité ambiante.</p>

                <p>Cependant, si la présence de mérule est identifiée rapidement, notamment grâce à l'intervention d'un
                    diagnostiqueur immobilier, un traitement est envisageable. Il implique l'application d'un fongicide
                    tous les 20 cm, la restauration de l'aération, et la rénovation de la zone endommagée. <strong>
                        En revanche,
                        en cas de découverte tardive, de nombreux propriétaires se sont vus contraints de démolir des murs,
                        voire des maisons entières, et de brûler les parties contaminées par
                    </strong> <strong>la mérule</strong>.</p>

                <p><strong>
                    Quant à la question de l'obligation du diagnostic mérule, contrairement au diagnostic termites, il
                        n'est pas obligatoire en cas de vente, sauf dans les zones soumises à un arrêté préfectoral.
                </strong>
                    Cependant, si le bien se situe dans un département à risque, le vendeur a la responsabilité de
                    fournir une note à l'acquéreur mentionnant l'existence du risque de mérule. Cette note doit être
                    annexée à la promesse de vente ou à l'acte définitif de vente.</p>

                <p>Le diagnostic mérule, bien que non obligatoire, est avant tout informatif. Il est fortement
                    recommandé de le réaliser en cas de signes apparents de la présence du champignon, ce qui permet au
                    vendeur de se prémunir d'un éventuel recours de l'acquéreur au titre de la garantie des vices
                    cachés.</p>

                <p>En France, la présence de mérule est plus marquée dans certaines régions, notamment dans les
                    Hauts-de-France, en Bretagne, en Normandie, et en Île-de-France. Environ 2000 communes sont
                    concernées par la prolifération du champignon, avec des taux variables d'impact dans chaque région.
                </p>

                <p><strong>La mérule</strong>, classé parmi les champignons lignivores, est redoutable car il peut se développer dans des
                    environnements classiques sans problèmes d'isolation, se nourrissant de l'humidité naturelle dégagée
                    par la cellulose du bois. Sa propagation rapide et son potentiel destructeur font du mérule un sujet
                    préoccupant pour les propriétaires de biens immobiliers. Certains signes caractéristiques de la
                    présence de mérule comprennent une odeur d'humidité et de champignon persistante, une déformation
                    des poutres, un dépôt de poussière orange, et une surface de mérule représentée par une mousse
                    blanche et fine.</p>
                <p>Lors du diagnostic mérule obligatoire, un professionnel certifié en diagnostic immobilier peut
                    prélever un échantillon de la surface mousseuse pour une analyse en laboratoire afin de confirmer
                    l'expertise.</p>
                <p>Le diagnostic mérule doit être réalisé par un diagnostiqueur immobilier certifié et expérimenté.
                    Avant son intervention, il est recommandé de préparer l'accès aux pièces concernées en libérant les
                    poutres, plafonds et planchers pour permettre au diagnostiqueur d'effectuer les inspections
                    nécessaires. Fournir des informations détaillées au diagnostiqueur, telles que des plans, peut
                    également faciliter le processus.</p>
                <p>Le rapport du diagnostic mérule comprend des informations détaillées sur l'intervention, les parties
                    du logement qui ont été ou non consultées, les constats de l'expert, les conditions particulières de
                    l'intervention, ainsi que les obligations du propriétaire ou bailleur s'il y en a. Il contient
                    également des préconisations pour le traitement des mérules.</p>
                <p>En ce qui concerne la <strong>validité du diagnostic mérule obligatoire</strong>, il est généralement recommandé qu'il
                    ait été réalisé jusqu'à 6 mois maximum avant la signature de la promesse de vente. Les coûts du
                    diagnostic peuvent varier en fonction de la taille du bien diagnostiqué.</p>

            </div>
        </div>
    </div>
</div>
);
};

export default Merule;