import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Plomb from "./components/Plomb";
import Amiante from "./components/Amiante";
import Assainissement from "./components/Assainissement";
import Audit from "./components/Audit";
import Dpe from "./components/Dpe";
import Dtg from "./components/Dtg";
import Electricite from "./components/Electricite";
import Partners from "./components/Partners";
import Erp from "./components/Erp";
import Gaz from "./components/Gaz";
import Merule from "./components/Merule";
import Mesurage from "./components/Mesurage";
import Termites from "./components/Termites";
import Map from "./components/Map";
import Quote from "./components/Quote";
import { useState, Suspense } from "react";
import TermsAndConditions from "./components/TermsAndConditions";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";


function App() {
  const [isQuote, setIsQuote] = useState(false);

  const handleNavClick = (section) => {
    setIsQuote(section === "quote");
    // document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={"side-header"}
      >
        <Suspense fallback={<PreLoader />}>

        <div id="main-wrapper">
          <Header handleNavClick={handleNavClick}/>

          <div id="content" role="main">
            { isQuote ? (
              
              <Quote/>
            ):(
              <>
                <Home handleNavClick={handleNavClick}/>
                <AboutUs/>
                <Services/>
                <Testimonials/>
                <Partners/>
                <Map/>
                <Contact/>
              </>
            )}
          </div>
          <Footer handleNavClick={handleNavClick}/>
        </div>
        {/* back to top */}
        <Tooltip text="Remonter" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fa fa-chevron-up"></i>
          </span>
        </Tooltip>

        <TermsAndConditions/>
        <Plomb/>
        <Gaz/>
        <Electricite/>
        <Dpe/>
        <Merule/>
        <Amiante/>
        <Mesurage/>
        <Termites/>
        <Assainissement/>
        <Erp/>
        <Audit/>
        <Dtg/>
        
        </Suspense>
      </div>
    </>
  );
}

export default App;
