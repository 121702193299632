import React from "react";

const Plomb = () => {
return (
<div id="plomb" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Diagnostic Plomb
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">
                <p>
                    <strong>Le Diagnostic Plomb</strong>, également appelé CREP (Constat de Risque d’Exposition au Plomb), est une
                    exigence lors de la <strong>vente ou de la location</strong> d'une propriété construite <strong>avant le 1er janvier 1949.</strong>
                    Avant cette date, les artisans utilisaient fréquemment des peintures contenant du plomb, connues
                    sous le nom de céruse, en particulier dans les zones humides de la maison. Le plomb est depuis
                    longtemps identifié comme responsable du saturnisme, une intoxication grave affectant
                    particulièrement les enfants vivant dans des immeubles anciens et dégradés.
                </p>
                <p>
                    Bien que les peintures au plomb aient été interdites dès 1948, des traces peuvent subsister sur des
                    éléments tels que les murs, radiateurs et fenêtres, souvent dégradés et susceptibles d'être ingérés
                    par les enfants. Les objectifs du diagnostic sont multiples : mesurer la concentration en plomb dans
                    les peintures par <strong>fluorescence X</strong>, évaluer leur état de dégradation, informer les parties concernées
                    de la présence de plomb pouvant nécessiter des travaux, et identifier les situations d’insalubrité.
                </p>
                <p>
                    <strong>Le diagnostic immobilier plomb</strong> doit être effectué sur les bâtiments d’habitation construits avant le
                    1er janvier 1949, qu'ils soient destinés à la vente ou à la location (CREP pour les parties
                    privées). Il est également obligatoire pour les parties communes des immeubles d’habitation
                    construits avant le 1er janvier 1949, avec une recherche de plomb devant être effectuée avant le 11
                    août 2008 pour les parties communes.
                </p>
                <p>
                    <strong>Depuis le 1er novembre 2007, seul un diagnostiqueur certifié</strong>, habilité et assuré peut réaliser ce
                    diagnostic. L'examen de la présence de plomb est effectué à l'aide d'un appareil à fluorescence X,
                    couvrant aussi bien les revêtements intérieurs qu'extérieurs. Le diagnostic classifie chaque
                    revêtement selon son état de conservation, noté de 1 à 3. Si des revêtements dégradés (classe 3)
                    sont identifiés, des travaux de suppression du risque plomb sont obligatoires.
                </p>
                <p>
                    <strong>En cas de vente, le propriétaire n'est pas contraint d'effectuer les travaux lui-même</strong>, mais
                    l'acheteur acquiert la propriété en connaissance de cause. En cas de location, les travaux sont à la
                    charge du propriétaire, qui doit informer les locataires. Le diagnostiqueur doit transmettre une
                    copie du rapport à l’Agence Régionale de Santé en cas de facteurs d'insalubrité. Si la teneur en
                    plomb présente un danger, le maire ou le préfet peut ordonner des travaux de rénovation.
                </p>
                <p>La durée de validité du diagnostic plomb est d'un an pour une vente, six ans pour une location, sauf
                    en cas d'absence totale de plomb, où le diagnostic est valable à vie.</p>
            </div>
        </div>
    </div>
</div>
);
};

export default Plomb;