import React from "react";

import Slider from "react-slick";

const Partners = () => {

  const reviews = [
    {
      src : './images/partners/partner0.png',
    },
    {
      src : './images/partners/partner1.png',
    },
    {
      src : './images/partners/partner2.png',
    },
    {
      src : './images/partners/partner3.png',
    },
    {
      src : './images/partners/partner4.png',
    },
    {
      src : './images/partners/partner5.png',
    },
    {
      src : './images/partners/partner6.png',
    },
    {
      src : './images/partners/partner7.png',
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className="section"
    >
      <div className="container px-lg-5">
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              "text-light opacity-4"
            }
          >
            Partenaires
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              "text-dark"
            }
          >
            {" "}
            Partenaires
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 "
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid  d-inline-block"
                      height="50px"
                      src={value.src}
                      alt=""
                    />
                    
                  </div>
                  
                  
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>

    
  );
};

export default Partners;
