import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Quote = () => {
    const [sendingMail, setSendingMail] = useState(false);
    const [formData, setFormData] = useState({
        wish: '',
        type: '',
        shared: '',
        surface: '',
        rooms: '',
        constructionDate: '',
        gasInstallation: '',
        electricInstallation: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        postalCode: '',
        city: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSendingMail(true);
        fetch('mail-devis.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data =>  {
            document.getElementById("quote-form").reset();
            if (data.status === "success") {
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setSendingMail(false);
        })
        .catch((error) => {
            toast.error("Oups ! Une erreur est survenue.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error('Error:', error);
            setSendingMail(false);
        });
    };

    return (
        <section id="quote" className="section">
            <div className="container px-lg-5">
                <h1 className="text-center">Demande de devis gratuit</h1>
                <form onSubmit={handleSubmit} id="quote-form" className="container mt-5">
                    <h2>Vous souhaitez</h2>
                    <div className="btn-group mb-3" role="group">
                        <button type="button" className={`btn ${formData.wish === 'VENDRE' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, wish: 'VENDRE'})}>VENDRE</button>
                        <button type="button" className={`btn ${formData.wish === 'LOUER' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, wish: 'LOUER'})}>LOUER</button>
                    </div>

                    <h2>Sélectionnez un type de bien :</h2>
                    <div className="btn-group mb-3" role="group">
                        <button type="button" className={`btn ${formData.type === 'UNE MAISON' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, type: 'UNE MAISON'})}>UNE MAISON</button>
                        <button type="button" className={`btn ${formData.type === 'UN APPARTEMENT' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, type: 'UN APPARTEMENT'})}>UN APPARTEMENT</button>
                        <button type="button" className={`btn ${formData.type === 'AUTRE BIEN' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, type: 'AUTRE BIEN'})}>AUTRE BIEN</button>
                    </div>

                    <h2>Copropriété :</h2>
                    <div className="btn-group mb-3" role="group">
                        <button type="button" className={`btn ${formData.shared === 'OUI' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, shared: 'OUI'})}>OUI</button>
                        <button type="button" className={`btn ${formData.shared === 'NON' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, shared: 'NON'})}>NON</button>
                        <button type="button" className={`btn ${formData.shared === 'JE NE SAIS PAS' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, shared: 'JE NE SAIS PAS'})}>JE NE SAIS PAS</button>
                    </div>

                    <h2>Surface :</h2>
                    <div className="btn-group mb-3" role="group">
                        {['-30M²', '30M²-50M²', '50M²-90M²', '90M²-110M²', '110M²-130M²', '130M²-150M²', '150M²-180M²', '+180M²'].map((range) => (
                            <button key={range} type="button" className={`btn ${formData.surface === range ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, surface: range})}>
                                {range}
                            </button>
                        ))}
                    </div>

                    <h2>Nombre de pièces principales :</h2>
                    <div className="btn-group mb-3" role="group">
                        {['Studio','T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7 ET +'].map((room) => (
                            <button key={room} type="button" className={`btn ${formData.rooms === room ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, rooms: room})}>
                                {room}
                            </button>
                        ))}
                    </div>

                    <h2>Date de permis de construire :</h2>
                    <div className="btn-group mb-3" role="group">
                        {['AVANT 1949', 'ENTRE 1949 ET 1997', 'ENTRE 1997 ET 2008', 'APRES 2008', 'JE NE SAIS PAS'].map((date) => (
                            <button key={date} type="button" className={`btn ${formData.constructionDate === date ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, constructionDate: date})}>
                                {date}
                            </button>
                        ))}
                    </div>

                    <h2>Vous disposez d'une installation gaz :</h2>
                    <div className="btn-group mb-3" role="group">
                        {['OUI DE - DE 15 ANS', 'OUI DE + DE 15 ANS', 'NON', 'JE NE SAIS PAS'].map((option) => (
                            <button key={option} type="button" className={`btn ${formData.gasInstallation === option ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, gasInstallation: option})}>
                                {option}
                            </button>
                        ))}
                    </div>

                    <h2>Vous disposez d'une installation électrique :</h2>
                    <div className="btn-group mb-3" role="group">
                        {['OUI DE - DE 15 ANS', 'OUI DE + DE 15 ANS', 'NON', 'JE NE SAIS PAS'].map((option) => (
                            <button key={option} type="button" className={`btn ${formData.electricInstallation === option ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFormData({...formData, electricInstallation: option})}>
                                {option}
                            </button>
                        ))}
                    </div>

                    <h2>Vos informations</h2>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Prénom" name="firstName" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Nom" name="lastName" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="email" className="form-control" placeholder="Email" name="email" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Téléphone" name="phone" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Adresse" name="address" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Code Postal" name="postalCode" onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <input type="text" className="form-control" placeholder="Ville" name="city" onChange={handleChange} required />
                    </div>

                    <button
                    id="submit-btn"
                    className="btn btn-primary rounded-pill d-inline-flex"
                    type="submit"
                    >
                    {sendingMail ? (
                        <>
                        <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Envoi en cours.....
                        </>
                    ) : (
                        <>Envoyer</>
                    )}
                    </button>
                    <ToastContainer />
                </form>
            </div>
        </section>
    );
};

export default Quote;
