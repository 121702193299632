import React from "react";

import Slider from "react-slick";

const Testimonials = () => {

  const reviews = [
    {
      name: "Margaux Raguenet",
      desc: "La totalité des diagnostiques à faire pour ma maison. 3h riches en informations plus intéressantes les unes que les autres. Enfin quelqu/’un qui explique clairement ce qu’il est en train de faire tout en donnant un côté ludique et simple aux explications. Très professionnel. Je recommande les yeux fermés! Un grand merci à ce monsieur.",
      rating: 5,
    },
    {
      name: "Carole Barents",
      desc: "Je suis très satisfaite de la visite de Mr Duvieubourg concernant le diagnostic de mon appartement, il m'a écouté, conseillé et surtout m'a appris beaucoup de choses très utiles pour la suite de mes travaux. N'hésitez pas à le contacter !!! Je recommande à 100%.",
      rating: 5,
    },
    {
      name: "Valérie Coutrel",
      desc: "Ponctuel pour l’heure du rendez-vous Tout s’est bien passé pendant le diagnostic, Monsieur Duvieubourg, très discret, explique l’utilité des différentes mesures qu’il prend et répond aux questions que l’on se pose. A conseiller 👍🏻Fait à Boulogne sur mer, le mardi 21 novembre 2023",
      rating: 5,
    },
    {
      name: "Hugo Bodart",
      desc: "Très compétent , plein de bon conseil . N’hésite pas à expliquer si vous êtes demandeur , bref un très bon professionnel que je recommande a 200%",
      rating: 5,
    },
    {
      name: "Edwige Dohen",
      desc: "J'ai tout d'abord apprécié le premier contact par téléphone avec Mr Duvieubourg qui m'a donné toutes les explications concernant les différents diagnostics. La prise de rdv rapide,sa ponctualité,son professionnalisme ont conforté mon choix. De plus, il a pris le temps de répondre à toutes mes questions. Je recommande donc sans hésitation Diag Précision 62.",
      rating: 5,
    },
    {
      name: "MrBeurdy",
      desc: "Personne très professionnel expliquant ce qu'il fait et travaille très bien",
      rating: 5,
    },
    {
      name: "Martine Bourgois",
      desc: "Personne très compétente expliquant à ses clients chaque diagnostic sur les éléments d’intervention.",
      rating: 5,
    },
    {
      name: "Eddy Lemaire",
      desc: "Excellent travail. Diagnostic fait en temps et en heure. Très sympathique et professionnel, le diagnostiqueur a su répondre à mes interrogations. Je recommanderais ce service",
      rating: 5,
    },
    {
      name: "Romain Vasseur",
      desc: "Très compétent dans le domaine et professionnel, avec pleins de conseils. Je recommande sans hésiter 👍👍👍👍",
      rating: 5,
    },
    {
      name: "Jose Simart",
      desc: "Excellente prestation,très réactif,professionnel.Monsieur très aimable et agréable je vous recommande vivement cette entreprise.",
      rating: 5,
    },
    {
      name: "Brigitte Lambert",
      desc: "Excellente prestation. Merci pour votre disponibilité et vos conseils. Professionnel à recommander.",
      rating: 5,
    },
    {
      name: "Christine Leger",
      desc: "Sérieux et rapide ,je recommande vivement",
      rating: 5,
    },
    {
      name: "Beatrice Nevejans",
      desc: "Excellente prestation Très compétent dans le domaine Explications claires... Conseils pertinents A recommander ++++",
      rating: 5,
    },
    {
      name: "M H",
      desc: "Professionnel très sérieux, passionné par son travail je recommande",
      rating: 5,
    },
    {
      name: "Gérard Cochard",
      desc: "Prestation de qualité et très professionnelle. A conseiller sans réserves",
      rating: 5,
    },
    {
      name: "Frederic Carpentier",
      desc: "très satisfait de la prestation rapide et efficace. je recommande",
      rating: 5,
    },
    {
      name: "Philippe Boin",
      desc: "Personne agréable et beaucoup de professionnalisme et beaucoup de conseils je recommande",
      rating: 5,
    },
    {
      name: "Corinne DUQUENOY",
      desc: "Diagnostics précis, travail correctement réalisé. A recommander",
      rating: 5,
    },
    {
      name: "Steph Boutour",
      desc: "",
      rating: 5,
    },
    {
      name: "Edwin Paris",
      desc: "",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className="section "
    >
      <div className="container px-lg-5">
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2 className="text-24  text-uppercase fw-600 w-100 mb-0 text-light opacity-4">
            Témoignages
          </h2>
          <p className="text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 text-dark">
            {" "}
            Nos clients
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 bg-light"
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          "text-dark"
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 text-dark"
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>

    
  );
};

export default Testimonials;
