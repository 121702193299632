import React from "react";

const Gaz = () => {
return (
<div id="gaz" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Gaz
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p>Lorsque <strong>l'installation intérieure de gaz</strong> d'une habitation a moins de quinze ans, le vendeur ou le
                    bailleur n'est pas obligé de faire contrôler celle-ci par un expert. De plus, si la vérification a
                    été effectuée récemment (moins de trois ans avant la vente ou moins de six ans avant la location),
                    le propriétaire peut utiliser le précédent diagnostic pour conclure le contrat.</p>

                <p>Le diagnostic gaz est une obligation réglementaire. En cas de vente ou de location d'un logement avec
                    une <strong>installation intérieure de gaz</strong> âgée de plus de quinze ans, un état relatif à cette installation
                    doit obligatoirement figurer dans le dossier de diagnostics.</p>

                <p>Les diagnostiqueurs immobiliers s'assurent de la sécurité de l'installation en examinant tous les
                    équipements fixes à travers lesquels le gaz circule, y compris les équipements de cuisine et de
                    chauffage. Ils vérifient également la tuyauterie et s'assurent d'une ventilation adéquate pour une
                    évacuation correcte de l'air. L'objectif de ce diagnostic est de garantir la sécurité des occupants
                    en identifiant d'éventuels problèmes de fuite, de combustion ou de ventilation, étant donné les
                    risques importants liés au gaz.</p>

                <p><strong>Le diagnostic gaz est obligatoire</strong> lors de la vente d'une habitation depuis 2007 et pour la location
                    des logements construits avant 1975 à partir du 1er juillet 2017 (loi Alur). À partir du 1er janvier
                    2018, ce diagnostic est exigé pour toute location ou vente d'un logement équipé d'une installation
                    gaz de plus de 15 ans. La seule exception est pour les bouteilles de gaz reliées directement à un
                    appareil de cuisson par un conduit souple, sans installation fixe.</p>

                <p>Seul un diagnostiqueur certifié gaz par un organisme accrédité COFRAC peut réaliser le diagnostic
                    gaz. L'intervention du diagnostiqueur porte sur les éléments visibles et accessibles de
                    l'installation, sans démontage des appareils.</p>

                <p>En cas de détection d'anomalies, celles-ci sont notées selon <strong>leur gravité (A1, A2, ou DGI)</strong> et
                    détaillées dans le rapport. Si une anomalie grave (DGI) est identifiée, le diagnostiqueur peut
                    condamner tout ou partie de l'installation et alerter le distributeur. Pour les anomalies moins
                    graves (A1 ou A2), aucune obligation immédiate de travaux n'est imposée, mais les acquéreurs doivent
                    en être informés.</p>

                <p>La durée de validité du diagnostic gaz est de 3 ans pour une vente et de 6 ans pour une location,
                    <strong>transmissible au moment de l'acte de vente ou de la signature du bail.</strong> Si le diagnostic n'est pas
                    valide à ce moment-là, l'acheteur ou le locataire peut demander une révision du prix ou même
                    l'annulation de la transaction. Le non-respect de cette obligation peut entraîner des sanctions
                    civiles, notamment en cas de vices cachés découverts par l'acheteur après la transaction.</p>
            </div>
        </div>
    </div>
</div>
);
};

export default Gaz;