import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);

    const formData = new FormData(form.current);

    fetch('mail.php', {  // Make sure to use the correct path to your PHP file
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        document.getElementById("contact-form").reset();
        if (data.status === "success") {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setSendingMail(false);
      })
      .catch((error) => {
        toast.error("Oups ! Une erreur est survenue.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Error:', error);
        setSendingMail(false);
      });
  };

  return (
    <section id="contact" className="section bg-light">
      <div className="container px-lg-5">
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={"text-24 text-uppercase fw-600 w-100 mb-0 text-light opacity-4"}>
            Contact
          </h2>
          <p className={"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 text-dark"}>
            {" "}
            Laissez-nous un message
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end */}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-4 order-1 order-md-0 text-center text-md-start">
            <p><a href="tel:+33629711717" className="text-3 mb-1">
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              06 29 71 17 17
            </a></p>
            <p>
            <a href="mailto:contact@diagaudit-expertise.fr" className="text-3 mb-4">
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              contact@diagaudit-expertise.fr
            </a>
            </p>
            <h2 className={"mb-3 text-5 text-uppercase"}>
              Suivez-nous
            </h2>
            <ul className={"social-icons justify-content-center justify-content-md-start"}>
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="bottom">
                  <a
                    href="http://www.facebook.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-google">
                <Tooltip text="Linkedin" placement="bottom">
                  <a
                    href="https://www.linkedin.com/in/david-duvieubourg/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Linkedin"
                  >
                  <i className="fa-brands fa-linkedin"></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-8 order-0 order-md-1">
            <h2 className={"mb-3 text-5 text-uppercase text-center text-md-start"}>
              Dites-nous tout
            </h2>
            <form
              className=""
              id="contact-form"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-xl-6">
                  <input
                    name="user_name"
                    type="text"
                    className="form-control"
                    required
                    placeholder="Nom"
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    name="user_email"
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email"
                  />
                </div>
                <div className="col">
                  <textarea
                    name="message"
                    className="form-control"
                    rows={5}
                    required
                    placeholder="Message........"
                    defaultValue={""}
                  />
                </div>
              </div>
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary rounded-pill d-inline-flex"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Envoi en cours.....
                    </>
                  ) : (
                    <>Envoyer</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
