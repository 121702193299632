import React from "react";

const Amiante = () => {
return (
<div id="amiante" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                    Amiante
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">

                <p><strong>
                    Pour vendre un bien construit avant le 1er juillet 1997, le Code de la construction et de
                        l'habitation impose aux propriétaires de transmettre aux acquéreurs un document spécifique appelé
                        "état d'amiante avant-vente
                </strong>". Cette obligation a pour objectif d'informer les futurs acquéreurs de
                    la présence ou de l'absence de matériaux et produits contenant de l'amiante dans le bien immobilier.
                </p>

                <p>D'autre part, dans le cadre de la location d'un bien en copropriété, lorsque le permis de construire
                    est antérieur au 1er juillet 1997, les propriétaires doivent détenir un rapport connu sous le nom de
                    "<strong>dossier amiante des parties privatives (DAPP)</strong>". Contrairement au dossier de diagnostics techniques
                    (DDT) qui est généralement constitué lors de la location, le DAPP ne doit pas nécessairement être
                    <strong>
                        intégré au dossier de diagnostics joint au bail. Cependant, si les locataires souhaitent avoir accès
                        à ce document, ils doivent en avoir la possibilité.
                    </strong></p>

                <p>Points clés :
                    En résumé, ces documents visent à assurer une information transparente sur la présence d'amiante
                    dans les biens immobiliers, que ce soit dans le cadre d'une vente ou d'une location. Ils contribuent
                    à la sécurité et à la protection des occupants en fournissant des informations essentielles sur la
                    qualité de l'air intérieur et sur les éventuels risques liés à la présence d'amiante.</p>

                <p><strong>Utilisation et Propriétés de l'Amiante :</strong> L'amiante était autrefois considéré comme un "matériau
                    miracle" en raison de ses propriétés d'isolation thermique, mécanique, acoustique, et de sa
                    résistance au feu. Il a été largement utilisé dans divers secteurs, en particulier dans la
                    construction.</p>

                <p><strong>Toxicité et Interdiction :</strong> La toxicité de l'amiante pour la santé humaine à moyen et long terme est
                    évoquée. Bien que cette toxicité ait été prouvée depuis longtemps, l'interdiction formelle de
                    l'amiante en France n'a été mise en place qu'en 1997. Cette décision a été prise en raison des
                    risques sérieux que l'amiante présente pour la santé.</p>

                <p>Présence Persistante : Malgré l'interdiction, des millions de tonnes d'amiante ont été utilisées dans
                    la construction au fil des décennies. Il est souligné que l'amiante reste encore présent dans de
                    nombreux bâtiments aujourd'hui.</p>

                <p><strong>Préoccupation Sanitaire et Diagnostics :</strong> L'amiante est identifié comme la première cause de décès
                    liés au travail (hors accidents du travail). Les autorités ont réagi en mettant en place des
                    diagnostics visant à surveiller et réduire progressivement la présence d'amiante dans le paysage
                    immobilier français.</p>

                <p><strong>Objectifs des Diagnostics Amiante :</strong> Deux objectifs majeurs des diagnostics amiante sont mentionnés.
                    D'abord, exonérer le propriétaire du bien immobilier de la garantie des vices cachés. Ensuite,
                    informer l'acquéreur sur la présence ou l'absence de Matériaux et Produits Contenant de l'Amiante
                    (MPCA), avec des détails sur la localisation et l'état de conservation en cas de présence.</p>

                <p><strong>Seul un diagnostiqueur certifié est habilité à établir ce diagnostic avant-vente</strong>. Il inspectera les
                    matériaux et produits susceptibles de contenir de l’amiante conclura à la présence ou non d’amiante.
                    En cas de doute, il pourra procéder au prélèvement d'un échantillon pour expertise par à un
                    laboratoire agréé.</p>

                <p>La recherche d'amiante mentionnée est importante pour assurer une évaluation complète de la présence
                    éventuelle de ce matériau dans le bien immobilier. Voici un résumé des zones spécifiques prises en
                    compte lors de cette recherche :</p>
                <p>Dalles de sols : Certains revêtements de sol, notamment les dalles, peuvent être susceptibles de
                    contenir de l'amiante.</p>
                <p><strong>Faux plafonds :</strong> Les faux plafonds peuvent contenir des matériaux qui pourraient renfermer de
                    l'amiante. Cela inclut les revêtements utilisés pour les faux plafonds.</p>
                <p><strong>Enduits projetés :</strong> Les enduits appliqués sur les surfaces peuvent également être vérifiés pour
                    détecter la présence d'amiante.</p>
                <p><strong>Matériaux en fibre ciment :</strong> Les matériaux en fibre ciment, tels que les panneaux de revêtement
                    extérieur, peuvent contenir de l'amiante.</p>
                <p><strong>Calorifugeages :</strong> Les matériaux isolants utilisés pour le calorifugeage, visant à prévenir la perte de
                    chaleur, peuvent renfermer de l'amiante.</p>
                <p><strong>Flocages :</strong> Les revêtements isolants projetés, ou flocages, peuvent également être examinés pour
                    détecter la présence d'amiante.</p>
                <p><strong>Matériaux extérieurs :</strong> Depuis 2013, la recherche d'amiante a été étendue aux matériaux extérieurs, y
                    compris les toitures, bardages et conduits. Cela élargit la portée du diagnostic pour inclure des
                    éléments structurels importants.</p>

            </div>
        </div>
    </div>
</div>
);
};

export default Amiante;