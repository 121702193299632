const Audit = () => {
return (
<div id="audit" className="modal fade" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className= "modal-content">
            <div className="modal-header">
                <h5 className="modal-title" >
                Audit énergétique
                </h5>
                <button type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body p-4">
                <p><strong>À partir du 1er avril 2023</strong>, toute maison individuelle et tout immeuble détenu en monopropriété, mis en
                vente, devront obligatoirement subir un <strong>audit énergétique</strong> s'ils sont classés en <strong>catégorie F ou G</strong>, en
                plus du Diagnostic de performance énergétique (DPE). Cette mesure vise à progressivement éliminer les
                logements les plus énergivores du marché immobilier français. L'<strong>audit énergétique</strong> complétera le DPE en
                allant au-delà de l'évaluation de la performance énergétique.</p>
                <p>En s'appuyant sur le DPE, l'<strong>audit énergétique</strong> proposera des solutions de rénovation, estimera les coûts
                associés, prévoira les économies potentielles, et évaluera les aides financières disponibles. Une
                analyse approfondie de la structure du bâtiment, des systèmes d'isolation, des ouvertures, des
                équipements de production d'eau chaude, de ventilation, etc., sera réalisée pour déterminer les travaux
                nécessaires. <strong>Les propositions de travaux pourront être réalisées en une ou plusieurs phases,</strong> avec pour
                objectif d'améliorer suffisamment la performance énergétique du bien pour atteindre la classe
                énergétique C.</p>
                <p> À partir du 1er avril 2023, l'<strong>audit énergétique</strong> sera transmis au potentiel acquéreur dès la première
                visite du bien, similairement au DPE. Il sera ensuite annexé à la promesse ou au compromis de vente, et
                enfin inclus dans l'acte authentique.</p>
            </div>
        </div>
    </div>
</div>
);
};

export default Audit;