import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content "
          }
        >
          <div className="modal-header">
            <h5 className="modal-title ">
            Mentions légales
            </h5>
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">

              <h5>Éditeur du site</h5><br/>
              289 Avenue Antoine de Saint Exupéry 62100 Calais <br/>
              <strong>Téléphone:</strong> 03 21 34 82 44 ou 06 29 71 17 17<br/>
              <strong>Mail :</strong> contact@diagaudit-expertise.fr<br/>
              <strong>SIRET:</strong> 922 678 289<br/>
              <strong>Capital social:</strong> 2000,00 EURO<br/>
              <strong>TVA:</strong> FR05922678289<br/>
              <strong>Statut juridique :</strong> SASU Société par actions simplifiée à associé unique<br/>
              <strong>Activité:</strong> Diagnostic immobilier - DPE<br/>
              <strong>Responsable de publication :</strong> David DUVIEUBOURG<br/><br/>


              <h5>Site réalisé par</h5>
                    OPALIA<br/>
                    SAS au capital de 1500 euros<br/>
                    Enregistré au RCS de Dunkerque sous le numéro Dunkerque B 949 087 233<br/>
                    24, place du Général de Gaulle 59630 Bourbourg, France<br/>
                    contact@opalia.dev<br/>
                    SIRET : 94908723300019<br/>
                    TVA : FR50949087233<br/><br/>

              <h5>Hébergeur du site</h5>
                  Hébergement du site par<br/>
                  OVH SAS<br/>
                  2 rue Kellermann, 59100 Roubaix, France.<br/>
                  +33 9 72 10 10 07<br/><br/>
              
              <h5>Médiation</h5>
              Conformément aux articles L.616-1 et R.616-1 du code de la consommation, notre société a mis en place un dispositif de médiation de la consommation.
              L'entité de médiation retenue est: SAS CNPM - MÉDIATION - CONSOMMATION.<br/><br/>


              <strong>En cas de litige, le consommateur pourra déposer sa réclamation sur le site :<br/></strong>
              <a href="http://cnpm-mediation-consommation.eu/">http://cnpm-mediation-consommation.eu/</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
