import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Plomb",
      desc: "Il offre la possibilité d'évaluer la concentration de plomb présente dans les revêtements de votre domicile et de vous renseigner sur l'état de préservation de ces surfaces. Il contribue à la prévention des cas de saturnisme chez les enfants.",
      icon: "fas fa-fill-drip",
      modalId: "plomb",
      color: "primary"
      
    },
    {
      name: "Gaz",
      desc: "Vérifier l'état de conservation et le bon fonctionnement de l'installation intérieure de gaz d'une habitation est essentiel. Ce diagnostic contribue à renforcer la sécurité des occupants en minimisant les risques potentiels tels que les fuites de gaz ou l'intoxication au monoxyde de carbone.",
      icon: "fas fa-fire",
      modalId: "gaz",
      color: "danger"
      
    },
    {
      name: "Electricité",
      desc: "Vérifier la santé du réseau électrique d'une habitation est essentiel pour garantir la sécurité des occupants. Ce diagnostic met en lumière d'éventuels travaux nécessaires pour assurer la conformité et la fiabilité du réseau électrique.",
      icon: "fas fa-plug-circle-bolt",
      modalId: "electricite",
      color: "warning"
      
    },
    {
      name: "DPE",
      desc: "Le diagnostic de performance énergétique (DPE) est un document qui révèle la performance énergétique d'un bien immobilier. Les résultats de cette évaluation dépendent de la consommation d'énergie du bien ainsi que de la quantité d'émissions de gaz à effet de serre associée. Il est obligatoire pour tout propriétaire vendeur ou bailleur de réaliser un DPE du bien immobilier, que ce soit en vue de sa mise en vente ou de sa location.",
      icon: "fas fa-house-circle-check", 
      modalId: "dpe",
      color: "success"
      
    },
    {
      name: "Mérule",
      desc: "Le diagnostic mérule, rendu obligatoire par la loi ALUR, vise à informer l'acquéreur d'un bien immobilier sur la présence éventuelle du champignon lignivore dans le bâtiment. Conformément à cette exigence, une expertise immobilière est réalisée, accompagnée d'un document écrit attestant de la présence ou de l'absence de mérule, particulièrement dans les départements soumis à un arrêté préfectoral.",
      icon: "fas fa-chart-area",
      modalId: "merule",
      color: "primary"
      
    },

    {
      name: "Amiante",
      desc: "Le diagnostic amiante est effectivement crucial pour informer de la présence ou de l'absence de matériaux contenant de l'amiante dans un logement. Cela permet de prendre des mesures appropriées pour assurer la sécurité des occupants. L'obligation de réaliser ce diagnostic s'applique notamment aux logements construits avant 1997, car l'utilisation de l'amiante dans la construction a été interdite en France à partir de cette date.",
      icon: "fas fa-solid fa-lungs",
      modalId: "amiante",
      color: "warning"
      
    },
    {
      name: "Mesurage",
      desc: "Elle impose aux propriétaires d’un bien à usage d’habitation, de renseigner les locataires ( BOUTIN ) sur la superficie habitable avec plus d’1m 80 de hauteur, ou d’informer le nouveau propriétaire (carrez) d’un bien en copropriété. Elle ne comprend pas les planchers à moins d’1m 80 du plafond. Ces superficies ne comprennent pas le garage, la cave…",
      icon: "fas fa-pen-ruler", 
      modalId: "mesurage",
      color: "success"
      
    },
    {
      name: "Termites",
      desc: "Le diagnostic termite est un examen obligatoire dans les zones définies par un arrêté préfectoral ou communal. Son objectif est de vérifier la présence ou l'absence de termites ou d'insectes xylophages dans un logement. Ces insectes peuvent causer des dégâts importants aux structures en bois des bâtiments.",
      icon: "fas fa-locust",
      modalId: "termites",
      color: "primary"
      
    },
    {
      name: "Assainissement",
      desc: "Le diagnostic de l'assainissement, également appelé diagnostic assainissement, vise à évaluer l'état de l'installation d'assainissement d'un logement. Il concerne tant les systèmes d'assainissement collectifs que non collectifs.",
      icon: "fas fa-faucet-drip",
      modalId: "assainissement",
      color: "danger"
      
    },
    {
      name: "ERP",
      desc: "Il informe les futures acquéreurs ou occupants des risques auxquels est exposé le bien :  inondations, risques miniers, industriels etc…",
      icon: "fas fa-industry",
      modalId: "erp",
      color: "warning"
      
    },
    {
      name: "Audit énergétique",
      desc: "Il s'agit d'une évaluation approfondie de l'efficacité énergétique et de l'impact environnemental des habitations individuelles ou des immeubles collectifs appartenant à un seul propriétaire. Sa réalisation est obligatoire pour les logements présentant une forte consommation d'énergie et/ou des émissions élevées de gaz à effet de serre (Classe F et G) lorsqu'ils sont mis en vente. Cet audit énergétique vise à dresser un état des lieux détaillé et chiffré.",
      icon: "fas fa-magnifying-glass-chart", 
      modalId: "audit",
      color: "success"
      
    },
    {
      name: "DTG-PPT",
      desc: "Le diagnostic technique global (DTG) permet d’informer les copropriétaires de l’état technique global de l’immeuble et recense les travaux potentiels à effectuer. Il sera présenté à la 1re assemblée générale des copropriétaires à la suite de sa réalisation ou de sa révision.",
      icon: "fas fa-city", 
      modalId: "dtg",
      color: "primary"
      
    },
  ];


  return (
    <section
      id="services"
      className="section bg-light"
    >
      <div className="container  px-lg-5">
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              "text-light  opacity-4"
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              "text-dark"
            }
          >
            Nos expertises
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          "bg-white"
                        }
                      >
                        <i className={service.icon + ' text-' + service.color} />
                      </div>
                      <h3 className="">
                        {service.name}
                      </h3>
                      <p
                        className="mb-0 "
                      >
                        {service.desc} <br/>
                        </p>
                        <a
                          className={"mt-2 btn border border-" +  service.color}
                          data-bs-toggle="modal"
                          data-bs-target={ "#" + service.modalId }
                          href={ "#" + service.modalId }
                        >
                          En savoir plus
                        </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end  */}
      </div>
    </section>
  );
};

export default Services;
