import React, { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link } from "react-scroll";

const Header = ({ homeRef, handleNavClick }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link
            href="#"
            smooth
            duration={500}
            style={{ cursor: "pointer" }}
            to="home"
            className="mb-lg-auto mt-lg-4"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
            aria-label="logo DiagAudit EXPERTISE"
          >
            <img id="logo" className=" d-block mx-auto" src="images/logo.png" alt="logo DiagAudit EXPERTISE" />
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse w-100 my-lg-auto "
                : "show navbar-collapse w-100 my-lg-auto"
            }
          >
            <ul className="navbar-nav text-lg-center my-lg-auto py-lg-3">
              <li className="nav-item">
                <Link
                  href="#"
                  target={homeRef}
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("home");
                  }}
                >
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  href="#"
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("about");
                  }}
                >
                  A propos
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  href="#"
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="services"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("services");
                  }}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  href="#"
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="testimonial"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("testimonial");
                  }}
                >
                  Témoignages
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("quote");
                  }}
                >
                  Devis gratuit
                </a>
              </li>
              <li className="nav-item">
                <Link
                  href="#"
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                    handleNavClick("contact");
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          
          <ul className="social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-flex">
          <a href="tel:+33629711717" className="text-3 m-auto">
            <span className="text-primary text-4 me-2">
              <i className="fas fa-phone" />
            </span>
            06 29 71 17 17
          </a>
            <li className="social-icons-facebook">
              <Tooltip text="Facebook" placement="top">
                <a
                  href="http://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook-f text-primary" />
                </a>
                
                
              </Tooltip>
              <Tooltip text="Linkedin" placement="top">
                <a
                  href="https://www.linkedin.com/in/david-duvieubourg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Linkedin"
                >
                <i className="fa-brands fa-linkedin text-primary"></i>
                </a>
                
                
              </Tooltip>
              
            </li>
          </ul>
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose);
            }}
            className={
              isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
            }
            id="navbar-toggler"
            type="button"
            aria-label="toggle navigation"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
