import React from "react"; 
const AboutUs = () => {
return (
<section id="about" className={"section"}>
  <div className="container px-lg-5">
    {/* Heading */}
    <div className="position-relative d-flex text-center mb-5">
      <h2 className={ "text-24  text-uppercase fw-600 w-100 mb-0 text-light opacity-4" }>
        A propos
      </h2>
      <p className={ "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0"}>
        Qui suis-je ?
        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
      </p>
    </div>
    {/* Heading end*/}
    <div className="row gy-5">
      {/* About me content start */}
      <div className="col-lg-12 col-xl-12 text-center text-lg-start">
        <h2 className={ "text-7 fw-600 mb-3 "}>
          <span className="text-primary">David Duvieubourg</span> votre expert en efficacité énergétique des bâtiments,
          sur toute la côte d'opale et ses environs.
        </h2>
        <p>
          Vous envisagez de mettre en vente votre propriété sur la côte d'opale et ses alentours ? Ou peut-être
          êtes-vous un propriétaire bailleur sur le point de conclure un contrat avec un nouveau locataire ? Chez DIAG
          AUDIT EXPERTISE, nous sommes à votre disposition pour tous les diagnostics obligatoires de votre dossier de
          diagnostics techniques (DDT) : diagnostic énergétique (DPE), constat relatif au risque de plomb, diagnostic
          électrique, diagnostic gaz, état des risques et pollution, et état d'amiante avant la vente etc... </p>
        <p>
          Que vous possédiez un local commercial, un cabinet ou un atelier, ou que vous soyez responsable d'un chantier
          de rénovation ou de démolition dans la région de Calais, nous sommes en mesure de vous apporter notre
          assistance. Certifiés pour le DPE avec mention, nous sommes en mesure d'évaluer l'efficacité énergétique de
          votre bien en vue de sa mise en vente ou en location (immeubles etc...). Nous nous occupons également de la
          réalisation de votre dossier technique global (DTG) avec plan pluriannuel de travaux (PPT), si nécessaire,
          pour vos copropriétés.
        </p>
        <p>
          Dotés d'une formation approfondie en recherche d'amiante, nous inspectons les structures potentiellement
          dangereuses afin de vous fournir des conseils personnalisés visant à protéger la santé de vos travailleurs.
          Pour nous joindre rapidement, composez l'un des deux numéros de téléphone suivants : le 06 29 71 17 17 ou le
          03.21.34.82.44. Devis dans la journée, RDV et remise des rapports sous 48h selon dossier.

        </p>
        <p>
          Située dans la région nord de la France, la ville de Calais joue un rôle crucial en tant que principal point
          de liaison maritime avec l'Angleterre, notamment depuis la construction du Tunnel sous la Manche à la fin du
          XXe siècle. Les activités portuaires de cette cité de la Côte d'Opale contribuent significativement à son
          attrait économique. Si vous envisagez de vendre ou de louer un bien immobilier dans la région, il est
          impératif de réaliser plusieurs diagnostics immobiliers préalables tels que le DPE, les mesurages Carrez ou
          Boutin, l'état des termites en cas d'arrêté préfectoral, le diagnostic amiante pour les constructions
          antérieures à 1997, etc. En tant que professionnel certifié, DIAG AUDIT EXPERTISE est à votre service pour
          réaliser vos diagnostics immobiliers à Calais et vous accompagner dans l'ensemble de vos projets. »

        </p>
      </div>
    </div>
    {/* projects rewards counting start */}
    <div className={ "brands-grid separator-border mt-5 "}>
      <div className="row">

        <div className="col-6 col-md-3 ">
          <div className="featured-box text-center">
            <h4 className={ "text-12  mb-0 text-muted"}>
              <span><i className="fa-solid fa-file-signature text-danger"></i></span>
            </h4>
            <p className={"mb-0  text-danger" }>
              Devis gratuit
            </p>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="featured-box text-center">
            <h4 className={ "text-12  mb-0 text-muted"}>
              <span><i className="fa-regular fa-handshake text-primary"></i></span>
            </h4>
            <p className={"mb-0  text-primary" }>
              Sans engagement
            </p>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="featured-box text-center">
            <h4 className={ "text-12  mb-0 text-muted"}>
              <span><i className="fa-regular fa-circle-check text-success"></i></span>
            </h4>
            <p className={"mb-0  text-success" }>
              Diagnostiqueur certifié
            </p>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="featured-box text-center">
            <h4 className={ "text-12  mb-0 text-muted"}>
              <span><i className="fa-regular fa-lightbulb text-warning"></i></span>
            </h4>
            <p className={"mb-0  text-warning" }>
              Conseil & expertise
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* projects rewards counting end */}
  </div>
</section>
);
};

export default AboutUs;